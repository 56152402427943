import {useEffect, useState} from "react";
import Uppy from "@uppy/core";
import {Dashboard} from "@uppy/react";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import GoldenRetriever from '@uppy/golden-retriever';
import {sha256} from "crypto-hash";
import axios from 'axios';
import ShowModal from './ShowModal';

import "@uppy/core/dist/style.css";
import '@uppy/dashboard/dist/style.min.css';
import 'semantic-ui-css/semantic.min.css';
import '../index.css';

const fetchUploadApiEndpoint = async (endpoint, data, uploadID) => {
    const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(data),
    };
    const res = await fetch(`https://www.hommati.com/s3_upload/multipart-upload?${endpoint}&upload_id=${uploadID}`, fetchOptions);
    return res.json();
};

const UploaderComponent = ({uploadId}) => {
    const [showModal, setShowModal] = useState({
        status: false,
        modal_title: '',
        modal_head: '',
        modal_text: '',
    });
    const [uppy, setUppy] = useState(null);

    useEffect(() => {
        const uppyConfig = {
            debug: false,
            inline: true,
            autoProceed: true,
            showProgressDetails: true,
            waitForThumbnailsBeforeUpload: true,
            hideProgressAfterFinish: true,
            fileManagerSelectionType: 'both',
            proudlyDisplayPoweredByUppy: false,
            locale: {
                strings: {
                    dropPasteFiles: 'Drag files here, or %{browseFiles}'
                }
            }
        };

        const uppyInstance = new Uppy(uppyConfig);
        uppyInstance.use(GoldenRetriever);
        uppyInstance.use(ThumbnailGenerator, {thumbnailHeight: 300});
        uppyInstance.use(AwsS3Multipart, {
            createMultipartUpload: async (file) => {
                const arrayBuffer = await new Response(file.data).arrayBuffer();
                const fileHash = await sha256(arrayBuffer);
                const contentType = file.type;
                return fetchUploadApiEndpoint("create-multipart-upload", {
                    file,
                    fileHash,
                    contentType,
                }, uploadId);
            },
            listParts: (file, props) =>
                fetchUploadApiEndpoint("list-parts", {file, ...props}, uploadId),
            signPart: (file, props) =>
                fetchUploadApiEndpoint("sign-part", {file, ...props}, uploadId),
            abortMultipartUpload: (file, props) =>
                fetchUploadApiEndpoint("abort-multipart-upload", {file, ...props}, uploadId),
            completeMultipartUpload: (file, props) =>
                fetchUploadApiEndpoint("complete-multipart-upload", {file, ...props}, uploadId),
        });

        uppyInstance.on('upload-progress', (file, progress) => {
            console.log('upload progress', file.name, progress.bytesUploaded, progress.bytesTotal, window.performance.now());
        });

        uppyInstance.on('complete', (result) => {
            console.log('successful files:', result.successful);
            console.log('failed files:', result.failed);
            setShowModal({
                status: true,
                modal_title: 'Upload Successful!',
                modal_head: 'Your files have been uploaded.',
                modal_text: 'If you have any more files to upload, please close this notification. Otherwise, press the big green button to submit your files.',
            });
        });

        setUppy(uppyInstance);

        axios.get('/get-job-data/' + uploadId)
            .then(res => {
                const jobData = res.data;
                console.log('job_data:', jobData);
            });

        return () => {
            uppyInstance.close();
        };
    }, [uploadId]);

    return (
        <div>
            {uppy && <Dashboard uppy={uppy}/>}
            <ShowModal open={showModal?.status}
                       close_modal={() => setShowModal(undefined)}
                       modal_title={showModal?.modal_title}
                       modal_head={showModal?.modal_head}
                       modal_text={showModal?.modal_text}/>
        </div>
    );
};

export default UploaderComponent;
