//import axios from 'axios';
import React, { Component} from 'react'
import { 
    Modal, 
//    Button,
    Image,
    Header
} from 'semantic-ui-react'

export default class ShowModal extends Component {    
    constructor(props) {
        super(props)

    }

    viewClick = (link) => {
        window.open(link, "_blank", "noreferrer");
    }
    render(){
        return (
            <Modal
                className='fomantic_modal'
                closeIcon
                centered={true}
                open={this.props.open}
                onClose={() => this.props.close_modal()}
            >
            <Modal.Header>{this.props.modal_title}</Modal.Header>
            <Modal.Content image>
                <Image size='medium' src='https://static-assets.hommati.com/hommati-logo-svg-cropped.svg' wrapped />
                <Modal.Description>
                    <Header>{this.props.modal_head}</Header>
                    <p>{this.props.modal_text}</p>                
                </Modal.Description>
            </Modal.Content>
            </Modal>
        )
    }
}