//import AWS from 'aws-sdk';
import {
    ChonkyActions,
    FileBrowser,
    FileContextMenu,
    defineFileAction,
    FileActionHandler,
    FileList,
    setChonkyDefaults,
    ChonkyIconName
} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import path from 'path-browserify';
import React, { useCallback, useEffect, useState, useReducer } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { DeleteObjectCommand, ListObjectsV2Command, S3Client } from "@aws-sdk/client-s3";
import ImageModal from "./ImageModal";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import ReactDownLoad from 'reactjs-file-downloader'
import {Button} from "semantic-ui-react";



setChonkyDefaults({ 
    iconComponent: ChonkyIconFA,
    disableDragAndDropProvider: true,
});

const WASABI_S3_KEY = 'PZE0Z87O5J2DZ7BFGEZ9'
const WASABI_S3_SECRET = 'abzAqvEjtwSTemngmoJTzEa08E3RKMqzT8cS9Xmr'
var WASABI_BUCKET = 'hfn-upload-test'
var WASABI_PREFIX = ''

const s3 = new S3Client({
    region: 'us-central-1',
    apiVersion: '2006-03-01',
    credentials: {
        accessKeyId: WASABI_S3_KEY,
        secretAccessKey: WASABI_S3_SECRET
    },
    endpoint: 'https://s3.us-central-1.wasabisys.com'
});

const isImage = (filePath) => {
    switch (filePath.split('.').pop().toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return 'Image';
    }
}

// define custom handlers..
const deleteFiles = defineFileAction({
    id: 'delete_files',
    requiresSelection: true,
    fileFilter: file => file,
    button: {
        name: 'Delete file',
        toolbar: false,
        contextMenu: true,
        icon: ChonkyIconName.trash,
    },
})

/*
const downloadFiles = defineFileAction({
    id: 'download_files',
    requiresSelection: true,
    fileFilter: file => file,
    button: {
        name: 'Download file',
        toolbar: false,
        contextMenu: true,
        icon: ChonkyIconName.download,
    },
})
*/

const fileActions = [deleteFiles, ChonkyActions.DownloadFiles]

const deleteS3Files = async (bucket, keys) => {
    for (const this_key in keys) {
        var this_full_path_key = keys[this_key]
        var bucketParams = {
            Bucket: bucket,
            Key: this_full_path_key
        };
        const command = new DeleteObjectCommand(bucketParams);
        const response = await s3.send(command)
    }
}

const fetchS3BucketContents = async (bucket, prefix) => new Promise(function (resolve, reject) {
    if (prefix === null) {
        return false
    }
    const listParams = {
        Bucket: bucket,
        Delimiter: '/',
        Prefix: prefix
    };
    const command = new ListObjectsV2Command(listParams)
    s3.send(command)
        .then((response) => {
            const chonkyFiles = [];
            const s3Objects = response.Contents;
            const s3Prefixes = response.CommonPrefixes;

            if (s3Objects) {
                chonkyFiles.push(
                    ...s3Objects.map(
                        (object) => ({
                            id: object.Key,
                            name: path.basename(object.Key),
                            modDate: object.LastModified,
                            size: object.Size,
                        })
                    )
                );
            }

            if (s3Prefixes) {
                chonkyFiles.push(
                    ...s3Prefixes.map(
                        (prefix) => ({
                            id: prefix.Prefix,
                            name: path.basename(prefix.Prefix),
                            isDir: true,
                        })
                    )
                );
            }

            resolve(chonkyFiles);
            console.log('chonkyFiles', chonkyFiles)
        }).catch(() => reject(null));
});

export const S3Browser = () => {
        const {
            id
        } = useParams()
        const [isLoading, setLoading] = useState(false);
        const [error, setError] = useState(null);

        //if (typeof folderPrefix !== 'undefined'){
        //    console.log('folderPrefix before', folderPrefix);
        //}

        const [folderPrefix, setKeyPrefix] = useState('/');
        const [thisBucket, setThisBucket] = useState('');
        const [files, setFiles] = useState([]);

        const [showImages, setShowImages] = useState([]);

        console.log('folderPrefix', folderPrefix);


        useEffect(() => {
            if (!isLoading) {
                setLoading(true);
                axios.get('/get-job-data/' + id)
                    .then(res => {
                        var job_data = res.data;
                        if (typeof job_data === 'string') {
                            job_data = JSON.parse(job_data);
                        }
                        setKeyPrefix(job_data.prefix);
                        setThisBucket(job_data.bucket);
                        WASABI_BUCKET = job_data.bucket;
                        WASABI_PREFIX = job_data.prefix;
                        fetchS3BucketContents(job_data.bucket, job_data.prefix + '/')
                            .then(setFiles)
                            .catch((error) => setError(error.message))
                            .finally(() => setLoading(false));
                    }).catch(() => setLoading(false));
            }
        }, [folderPrefix, setFiles]);


        const folderChain = React.useMemo(() => {
            let folderChain;
            if (folderPrefix === '/') {
                folderChain = [];
            } else {
                let currentPrefix = '';
                folderChain = folderPrefix
                    .replace(/\/*$/, '')
                    .split('/')
                    .map(
                        (prefixPart) => {
                            currentPrefix = currentPrefix ?
                                path.join(currentPrefix, prefixPart) :
                                prefixPart;
                            return {
                                id: currentPrefix,
                                name: prefixPart,
                                isDir: true,
                            };
                        }
                    );
            }
            axios.get('/get-job-data/' + id)
                .then(res => {
                    var this_data = res.data;
                    if (typeof this_data === 'string') {
                        this_data = JSON.parse(this_data);
                        folderChain.unshift({
                            id: '/',
                            name: this_data.bucket,
                            isDir: true,
                        });
                    }
                    return folderChain;
                })
        }, [folderPrefix]);

    const handleFileAction = useCallback((data) => {
            console.log('handleFileAction invoked', data);
            // Check the action type based on the data id
            switch (data.id) {
                // Case for opening files
                case ChonkyActions.OpenFiles.id:
                    // Filter files that are not images
                    if (data.payload.files.filter(file => !isImage(file.id)).length > 0) {
                        // Show a warning if any non-image files are selected
                        enqueueSnackbar('You can only check out the pics, dude. The file you picked is a no-go!', {
                            variant: 'warning',
                        });
                    } else {
                        // Show images if all selected files are images
                        setShowImages(data.payload.files);
                    }
                    break;

                // Case for deleting files
                case ChonkyActions.DeleteFiles.id:

                    const deletingProcessId = setTimeout(() => {
                        // Delete selected files from S3
                        deleteS3Files(WASABI_BUCKET, data.state.selectedFiles.map(file => file.id))
                            .then(() => {
                                enqueueSnackbar('Deleted', {
                                    variant: 'info'
                                })
                                // Refresh the file list after deletion
                                fetchS3BucketContents(WASABI_BUCKET, WASABI_PREFIX + '/')
                                    .then(setFiles)
                                    .catch((error) => setError(error.message));
                            });
                        // Delete will start after 6 Second which is one more after popup hides
                        // Action bar is visible till 5 second
                        // If someone will hit cancel button this function won't execute as it will be
                        // cleared and delete won't be done
                    }, 6000)

                    // Show an info message about deleting
                    enqueueSnackbar('Deleting...', {
                        variant: 'info',
                        action: (snakeBarId) => <>
                            <Button positive onClick={() => {
                                clearTimeout(deletingProcessId);
                                closeSnackbar(snakeBarId);
                            }}>Cancel</Button>
                        </>
                    });


                    break;

                // Case for downloading files
                case ChonkyActions.DownloadFiles.id:
                    // Show an info message about downloading
                    enqueueSnackbar('Beginning download of selected files...', {
                        variant: 'info',
                    });

                    // Iterate through selected files and download each
                    data.state.selectedFiles.forEach(file => {
                        // Construct the download URL
                        const full_download_url = 'https://s3.us-central-1.wasabisys.com/' + WASABI_BUCKET + '/' + file.id;
                        const base_filename = file.id.split('/').pop();

                        // Trigger the download
                        ReactDownLoad(full_download_url, 'GET', base_filename);
                        console.log('downloading', full_download_url, base_filename);

                        // Show an info message for each file being downloaded
                        enqueueSnackbar('Downloading ' + base_filename, {
                            variant: 'info',
                        });
                    });
                    break;
            }
        }, [setKeyPrefix]
    );

    return (
        <div>
            {
                isLoading ? <div class="processing"><div class="continuous"></div></div> :
                    (
                        files.length > 0 ? <FileBrowser
                            files={files}
                            folderChain={folderChain}
                            fileActions={fileActions}
                            onFileAction={handleFileAction}
                            defaultFileViewActionId={ChonkyActions.EnableListView.id}
                        >
                            <FileList/>
                            <FileContextMenu/>
                        </FileBrowser> :
                            <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{fontSize: '1.5rem'}}>No Files Found</p>
                            </div>
                    )
            }
            {
                showImages.length > 0 &&
                <ImageModal images={showImages} WASABI_S3_BUCKET={WASABI_BUCKET}
                            onClose={() => setShowImages([])}/>
            }
        </div>
    );
};

export default S3Browser;