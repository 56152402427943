import React from 'react';
import { useParams } from 'react-router';

import UploaderComponent from './Uploader';

function UploaderWrapper() {

    const { id } = useParams();
    console.log(id);

    return (
        <UploaderComponent uploadId={id} />
    );
}

export default UploaderWrapper;