import React from 'react'
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    //useParams
} from "react-router-dom";


import * as Sentry from "@sentry/react";
import ErrorPage from './error-page';
import MainPanel from './components/MainPanel'
import {SnackbarProvider} from "notistack";
//import UploaderWrapper from './components/Uploader-Wrapper';


Sentry.init({
    dsn: "https://62ae8c93281a01a1665512ac85f7763b@o1168428.ingest.us.sentry.io/4507335953285120",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/hfn-react-uploader\.pages\/dev/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <div>no id specified...</div>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/upload/:id",
        element: <MainPanel />,
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <RouterProvider router={router} />
        </SnackbarProvider>
    </React.StrictMode>
);
