//import axios from 'axios';
import React, {Component, useCallback, useEffect, useState} from 'react'
import {
    Modal, //    Button,
    Image, Header
} from 'semantic-ui-react'

import '../styles/image_model.css';

const ImageModel = ({
                        images = [],
                        onClose = () => {},
                        WASABI_S3_BUCKET = '',
                        S3_BUCKET_URL = 's3.us-central-1.wasabisys.com',
                    }) => {

    const [visibleIndex, setVisibleIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const getFileURL = (file_path) => {
        return '//' + WASABI_S3_BUCKET + '.' + S3_BUCKET_URL + '/' + file_path;
    }

    useEffect(() => {
        if (images?.length > 0) {
            setVisibleIndex(0)
        }
    }, [images]);

    const closeModel = () => {
        onClose();
        setVisibleIndex(null);
        setIsLoading(true);
    }

    const previousImage = () => {
        setVisibleIndex((prevIndex) => {
            if (prevIndex > 0) {
                setIsLoading(true);
                return prevIndex - 1;
            }
            return prevIndex;
        });
    }

    const nextImage = () => {
        setVisibleIndex((prevIndex) => {
            if (prevIndex < images.length - 1) {
                setIsLoading(true);
                return prevIndex + 1;
            }
            return prevIndex;
        });
    }

    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'ArrowRight':
                nextImage()
                break;
            case 'ArrowLeft':
                previousImage()
                break;
            case 'Escape':
                closeModel()
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const changeLoading = useCallback(() => visibleIndex, [visibleIndex])

    return <div className="modal" style={{display: visibleIndex !== null ? 'flex' : 'none'}}>
        <div className="modal-content">
            <button type="button" className="close-button" aria-label="Close" onClick={closeModel}>
                <svg class="close-icon" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                     aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
            <button type="button" className="left-button" onClick={previousImage}
                    style={(images.length === 1 || (visibleIndex === 0)) ? {visibility: 'hidden'} : {}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="icon">
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                </svg>
            </button>
            <div class="image-container">
                <div className="image_loader" style={!isLoading ? {visibility: 'hidden'} : {}}>
                    <div>
                        <div class="loader"></div>
                    </div>
                </div>
                {
                    visibleIndex !== null &&
                    <img key={visibleIndex} src={getFileURL(images[visibleIndex]?.id)} onLoad={handleImageLoad}/>
                }
                <p className="file_name">{images[visibleIndex]?.name}</p>
            </div>
            <button type="button" className="right-button" onClick={nextImage}
                    style={(images.length === 1 || (images.length - 1  === visibleIndex)) ? {visibility: 'hidden'} : {}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="icon">
                    <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L12.17 12z"/>
                </svg>
            </button>
        </div>
    </div>
}

export default ImageModel;