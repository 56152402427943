import React from 'react'
import { Tab } from 'semantic-ui-react'
import UploaderWrapper from './UploaderWrapper';
import FileBrowser from './FileBrowser';
import 'semantic-ui-css/semantic.min.css';

const panes = [
    { menuItem: 'Uploader', render: () => <UploaderWrapper /> },
    { menuItem: 'File Management', render: () => <FileBrowser /> },
]

const MainPanel = () => <Tab panes={panes} />

export default MainPanel
